import React, { useEffect, useState } from 'react'
import Api from 'state/apis/api'

export default function ppd() {
  const [iframeUrl, setIframeUrl] = useState('')

  const callMetabase = () => {
    const url = '/v1/private/admin/user/dashboard/3'
    Api.get('wwwREST', url, {})
      .then((res: any) => {
        setIframeUrl(res.dashboardUrl)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(() => {
    callMetabase()
  }, [])

  return (
    <>
      <iframe src={iframeUrl} frameborder="0" width="800" height="1600" allowtransparency></iframe>
    </>
  )
}
